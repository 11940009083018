import React, { Component } from 'react'
import PhoneIcon from '../../styles/img/phone-icon.svg'
import MailIcon from '../../styles/img/mail-icon.svg'
import LocationIcon from '../../styles/img/location-icon-1.svg'

class GeneralInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    return(
        <div className='contact-card red-bar'>
            <h3>Contact F+S</h3>
            <div className='spacer'/>
            <p><strong>F+S Marketing</strong></p>
            <p>Monday through Friday 8 AM to 5 PM EST</p>
            <div className='spacer'/>
            <p><a href="https://goo.gl/maps/mhrhYF3RHcfABe9d8" target="_blank" rel="noreferrer"><img src={LocationIcon} alt='Location'/>30 Pine Street, Pittsburgh, PA 15223</a></p>
            <p><a href="tel:14127819003" target="_blank" rel="noreferrer"><img src={PhoneIcon} alt='Phone'/>1 (412) 781 - 9003</a></p>
            <p><a href="mailto:marketing@forms-surfaces.com" target="_blank" rel="noreferrer"><img src={MailIcon} alt='Email' />marketing@forms-surfaces.com</a></p>
        </div>
    )
  }
}

export default GeneralInfo
