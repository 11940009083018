import React, { Component } from 'react'
import PhoneIcon from '../../styles/img/phone-icon.svg'
import MailIcon from '../../styles/img/mail-icon.svg'
import LocationIcon from '../../styles/img/location-icon-1.svg'

class RepInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  onClick = () =>{
    this.props.onClick()
  }

  render() {
    return(
      <div className='contact-card grey-bar'>
          <h3>Your Local Territory Manager</h3>
          <div className='spacer'/>
          <p><strong>{this.props.contact.name}</strong></p>
          <p>{this.props.contact.title}</p>
          <div className='spacer'/>
          <p><img src={LocationIcon} alt='Location'/>{this.props.contact.location.split(' / ').join(', ')}</p>
          <p><a href={"tel:"+this.props.contact.phone} target="_blank" rel="noreferrer"><img src={PhoneIcon} alt='Phone' />{this.props.contact.phone}</a></p>
          <p><a href={"mailto:"+this.props.contact.email} target="_blank" rel="noreferrer"><img src={MailIcon} alt='Email' />{this.props.contact.email}</a></p>
      </div>
    )
  }
}

export default RepInfo
