import React from 'react'
import Contact from '../components/Contact/index.js'
import PrivateRoute from '../components/private-route'

const App = ({data, pageContext}) => {
  return(
    <PrivateRoute path="/contact" component={Contact}  />
  )
}

export default App
