import React from 'react'
import {
  FormControl,
  FormLabel,
  TextField } from '@mui/material'

export default function ContactForm({ handleSubmit, handleUpdate, disableSubmit, name, company, email, phone, message, history }) {
  return(<form
    name="Contact"
    className="entry-form contact-form"
    method="post"
    onSubmit={event => {
      handleSubmit(event)
    }}
    encType="multipart/form-data"
    netlify="true"
    data-netlify="true"
  >
    <input type="hidden" name="form-name" value="Contact" />
    <FormControl>
      <FormLabel>Name</FormLabel>
      <TextField
         required
         id="name"
         name="name"
         value={name || ''}
         variant="outlined"
         InputLabelProps={{
          shrink: true,
         }}
         onChange={handleUpdate}
        />
    </FormControl>
    <FormControl>
      <FormLabel>Company</FormLabel>
      <TextField
         required
         id="company"
         name="company"
         value={company || ''}
         variant="outlined"
         InputLabelProps={{
          shrink: true,
         }}
         onChange={handleUpdate}
        />
    </FormControl>
    <FormControl>
      <FormLabel>Email</FormLabel>
      <TextField
         required
         id="email"
         name="email"
         value={email || ''}
         placeholder="email@domain.com"
         variant="outlined"
         InputLabelProps={{
          shrink: true,
         }}
         onChange={handleUpdate}
        />
    </FormControl>
    <FormControl>
      <FormLabel>Phone</FormLabel>
      <TextField
         required
         id="phone"
         name="phone"
         value={phone || ''}
         placeholder="Phone Number"
         variant="outlined"
         InputLabelProps={{
          shrink: true,
         }}
         onChange={handleUpdate}
        />
    </FormControl>
    <FormControl>
      <FormLabel>Message</FormLabel>
      <TextField
         required
         id="message"
         name="message"
         value={message || ''}
         placeholder="Type your message here..."
         multiline={true}
         minRows={4}
         variant="outlined"
         InputLabelProps={{
          shrink: true,
         }}
         onChange={handleUpdate}
        />
    </FormControl>
  </form>)
}
