import axios from 'axios'
import { Auth } from 'aws-amplify'
const restPath = process.env.GATSBY_APIURL

export const getTerritoryManager = (searchText) => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          axios
            .post(restPath + '/tm-search', {
              searchText: searchText
            },
              {headers: {'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken }})
            .then(function(data) {
              if(data.data){
                if(data.data.errorMessage){
                  let dataError = new Error(data.data.errorMessage)
                  reject(dataError)
                }else{
                  let findOP = data.data.find((item)=>{
                    return item.DivisionName === 'Outdoor Product'
                  })
                  resolve(findOP)
                }
              }else{
                let dataError = new Error('Malformed Response')
                reject(dataError)
              }
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}
