import React, { Component } from 'react'
import Layout from '../layout-mobile'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Snackbar
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ContactForm from '../Form/contact-form'
import RepInfo from './rep-info'
import GeneralInfo from './general-info'
import LoadingBox from '../loading-box'
import { getUserInfo } from '../../utils/user'
import { getTerritoryManager } from '../../utils/q'
import { submitContact } from '../../utils/contact'
import { navigate, isBrowser } from '../../utils/misc'

class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      panel1: true,
      previousPassword: '',
      newPassword: '',
      errMsg: '',
      successMsg: '',
      userInfo: null,
      contactInfo: null,
      viewPasswordChange: false,
      viewUserEdit: false,
      formSubmitted: false,
      tmInfo: null,
      showContact: false,
      fsRep: {
        name: "F+S Marketing",
        title: "",
        phone: "800.451.0410",
        email: "marketing@forms-surfaces.com",
        location: "Pittsburgh / Carpenteria / Phoenix"
      }
    }
  }


  componentDidMount(){
    this.handleGetUserInfo()
  }

  handleGetUserInfo = () => {
    this.setState({
      loading: true
    })
    getUserInfo()
    .then(info=>{
      let contactInfo = {}
      contactInfo.name = info?.name?.first + ' ' + info?.name?.last
      contactInfo.company = info?.company
      contactInfo.email = info?.email
      contactInfo.phone = info?.phone
      contactInfo.zip = info?.postal_code
      if(contactInfo.zip){
        getTerritoryManager(contactInfo.zip)
        .then(tmInfo=>{
          let fsRep = {
            name: tmInfo.Territory.match(/\(([^)]+)\)/)[1],
            title: 'Territory Manager, Outdoor Products',
            phone: tmInfo.PrimaryPhone,
            email: tmInfo.Email,
            location: tmInfo.Territory.replace(/ *\([^)]*\) */g, "").replace(/OP/g, '')
          }
          this.setState({
            tmInfo: tmInfo,
            fsRep: fsRep
          })
        })
      }
      this.setState({
        userInfo: info,
        contactInfo: contactInfo,
        loading: false
      })
    })
    .catch(err=>{
      this.setState({
        errMsg: err.toString(),
        loading: false
      })
    })
  }

  handleGoToDashboard = () => {
    navigate('/dashboard')
  }

  handleCloseError = () => {
    this.setState({
      errMsg: ''
    })
  }

  handleCloseSuccess = () => {
    this.setState({
      successMsg: ''
    })
  }

  handleContactInfoUpdate = (e) => {
    let contactInfo = this.state.contactInfo
    contactInfo[e.target.name] = e.target.value
    this.setState({
      contactInfo: contactInfo
    })
  }

  handleContactFormSubmit = (e) => {
    e.preventDefault()
    if(isBrowser){
      submitContact(this.state.contactInfo)
      .then((data)=>{
        this.setState({
          successMsg: 'Success',
          formSubmitted: true
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          errMsg: error.toString()
        })
      })
    }
  }

  handleContactClose = () =>{
    this.setState({
      showContact: false
    })
  }


  render() {
    let functions = <>
    <Button variant="contained" onClick={()=>{
              this.setState({showContact: true})
    }}>Message My Territory Manager</Button>
    </>
    let location = []
    let cityState = []
    if(this.state.userInfo?.city){
      cityState.push(this.state.userInfo?.city)
    }
    if(this.state.userInfo?.stateProvince){
      cityState.push(this.state.userInfo?.stateProvince)
    }
    if(cityState.length > 0){
      location.push(cityState.join(', '))
    }
    if(this.state.userInfo?.postal_code){
      location.push(this.state.userInfo?.postal_code)
    }
    if(this.state.userInfo?.country){
      location.push(this.state.userInfo?.country)
    }
    let contactInfo = this.state.contactInfo
    return(
      <Layout titleText="Contact" path={this.props.path} showMenu={true} toolFunctions={functions}>
      {this.state.loading &&
        <LoadingBox />
      }
        <div className='row' style={{
          maxWidth: '1160px'
        }}>
          <div className='col-sm-6'>
            <RepInfo
              contact={this.state.fsRep}
              onClick={()=>{
                this.setState({
                  showContact: true
                })
              }}
            />
          </div>
          <div className='col-sm-6'>
              <GeneralInfo />
          </div>
        </div>
      <Snackbar open={(this.state.errMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseError}>
        <Alert elevation={6} variant="filled" severity="error" onClose={this.handleCloseError}>
          {(this.state.errMsg ? this.state.errMsg : '')}
        </Alert>
      </Snackbar>
      <Snackbar open={(this.state.successMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseSuccess}>
        <Alert elevation={6} variant="filled" severity="success" onClose={this.handleCloseSuccess}>
          {(this.state.successMsg ? this.state.successMsg : '')}
        </Alert>
      </Snackbar>
      <form
        name="Contact"
        className="entry-form contact-form"
        method="post"
        encType="multipart/form-data"
        netlify="true"
        data-netlify="true">
        <input type="hidden" name="form-name" value="Contact" />
      </form>
      <Dialog
        open={this.state.showContact}
      >
        <DialogTitle>
          <IconButton onClick={()=>{
              this.setState({showContact: false})
            }}>
              <CloseIcon />
          </IconButton>
          <span>Send Message</span>
        </DialogTitle>
        <DialogContent>
            <DialogContentText></DialogContentText>
            {!this.state.formSubmitted &&
              <ContactForm
              contactInfo={contactInfo}
              name={contactInfo?.name}
              company={contactInfo?.company}
              phone={contactInfo?.phone}
              email={contactInfo?.email}
              message={contactInfo?.message}
              handleSubmit={this.handleContactFormSubmit}
              handleUpdate={this.handleContactInfoUpdate}
              />
            }
            {this.state.formSubmitted &&
              <div>
                <h4>Thanks! We got your message</h4>
                <p>Your local representative will contact you in the next few days.
                All correspondence will be sent to either the phone number or email you provided.
                Please be aware some emails may get diverted to spam folders.
                In the meantime, feel free to configure and explore more F+S products.</p>
              </div>
            }
        </DialogContent>
        <DialogActions>
            {!this.state.formSubmitted &&
              <>
              <Button onClick={()=>{
                this.setState({showContact: false})
              }}>
              Cancel
              </Button>
              <Button variant='contained' onClick={this.handleContactFormSubmit}>
              Send
            </Button>
            </>
          }
          {this.state.formSubmitted &&
              <>
              <Button variant="contained" onClick={()=>{
                this.setState({showContact: false})
              }}>
              Close
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>
      </Layout>
    )
  }
}

export default Contact
